import axios from "axios";
import authHeader from '@/services/auth/AuthHeader';

class DashboardService {
    getDashboardData() {
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/dashboard/getDashboardData' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        //console.log('basicAuth: ' + JSON.stringify(basicAuth));
        return axios.get(url, { headers: basicAuth });
    }

    getDataForSoldOutAdminGui(locationId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/dashboard/getDataForSoldOutAdminGui' + '?merchant=' + user.merchant + "&locationId=" + locationId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }
}

export default new DashboardService();