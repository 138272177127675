import authHeader from "@/services/auth/AuthHeader";
import axios from "axios";

class ProductService {
    getDataForCategoryAdminGui(categoryId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/product/getDataForCategoryAdminGui' + '?merchant=' + user.merchant + '&categoryId=' + categoryId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getCategory(categoryId){
        let url = '/api/product/getCategory' + '?categoryId=' + categoryId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    editCategory(category){
        let url = '/api/product/editCategory';
        let basicAuth = authHeader();
        return axios.post(url, category,{
            headers: basicAuth });
    }

    deleteCategory(category){
        let url = '/api/product/deleteCategory';
        let basicAuth = authHeader();
        return axios.post(url,
            {
                merchant: category.merchant,
                categoryId: category.id,
                imageUrl: category.imageUrl
            },
            {headers: basicAuth}
        );
    }

    getCategories(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/product/getCategories' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getItem(itemId){
        let url = '/api/product/getItem' + '?itemId=' + itemId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getItems(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/product/getItems' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getItemsOfCategory(categoryId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/product/getItemsOfCategory' + '?merchant=' + user.merchant + '&categoryId=' + categoryId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getAddOnsOfAddOnGroup(addOnGroupId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/product/getAddOnsOfAddOnGroup' + '?merchant=' + user.merchant + '&addOnGroupId=' + addOnGroupId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getDataForItemAdminGui(categoryId, itemId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/product/getDataForItemAdminGui' + '?merchant=' + user.merchant + '&categoryId=' + categoryId + '&itemId=' + itemId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    createItem(item){
        let url = '/api/product/createItem';
        let basicAuth = authHeader();
        return axios.post(url, item,{
            headers: basicAuth });
    }

    editItem(item){
        let url = '/api/product/editItem';
        let basicAuth = authHeader();
        return axios.post(url, item,{
            headers: basicAuth });
    }

    deleteItem(item){
        let url = '/api/product/deleteItem';
        let basicAuth = authHeader();
        return axios.post(url,
                 {
                     merchant: item.merchant,
                     itemId: item.id,
                     imageUrl: item.imageUrl
                 },
                {headers: basicAuth}
                );
    }

    editItems(items){
        let url = '/api/product/editItems';
        let basicAuth = authHeader();
        return axios.post(url,
            items,
            {headers: basicAuth}
        );
    }

    editAddOns(addOns){
        let url = '/api/product/editAddOns';
        let basicAuth = authHeader();
        return axios.post(url,
            addOns,
            {headers: basicAuth}
        );
    }

    createCategory(category){
        let url = '/api/product/createCategory';
        let basicAuth = authHeader();
        return axios.post(url, category,{
            headers: basicAuth });
    }

    editCategories(categories){
        let url = '/api/product/editCategories';
        let basicAuth = authHeader();
        return axios.post(url,
            categories,
            {headers: basicAuth}
        );
    }

    editAddOnGroups(addOnGroups){
        let url = '/api/product/editAddOnGroups';
        let basicAuth = authHeader();
        return axios.post(url,
            addOnGroups,
            {headers: basicAuth}
        );
    }

    getAddOnGroups(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/product/getAddOnGroups' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    createAddOnGroup(addOnGroup){
        let url = '/api/product/createAddOnGroup';
        let basicAuth = authHeader();
        return axios.post(url, addOnGroup,{
            headers: basicAuth });
    }

    getDataForAddOnGroupAdminGui(addOnGroupId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/product/getDataForAddOnGroupAdminGui' + '?merchant=' + user.merchant + '&addOnGroupId=' + addOnGroupId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    getDataForAddOnAdminGui(addOnGroupId, addOnId){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/product/getDataForAddOnAdminGui' + '?merchant=' + user.merchant + '&addOnGroupId=' + addOnGroupId + '&addOnId=' + addOnId;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    createAddOn(addOn){
        let url = '/api/product/createAddOn';
        let basicAuth = authHeader();
        return axios.post(url, addOn,{
            headers: basicAuth });
    }

    editAddOn(addOn){
        let url = '/api/product/editAddOn';
        let basicAuth = authHeader();
        return axios.post(url, addOn,{
            headers: basicAuth });
    }

    editAddOnGroup(addOnGroup){
        let url = '/api/product/editAddOnGroup';
        let basicAuth = authHeader();
        return axios.post(url, addOnGroup,{
            headers: basicAuth });
    }

    deleteAddOnGroup(addOnGroup){
        let url = '/api/product/deleteAddOnGroup';
        let basicAuth = authHeader();
        return axios.post(url,
            {
                merchant: addOnGroup.merchant,
                addOnGroupId: addOnGroup.id
            },
            {headers: basicAuth}
        );
    }

    deleteAddOn(addOn){
        let url = '/api/product/deleteAddOn';
        let basicAuth = authHeader();
        return axios.post(url,
            {
                merchant: addOn.merchant,
                addOnId: addOn.id
            },
            {headers: basicAuth}
        );
    }

    getProducts(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = '/api/product/getProducts' + '?merchant=' + user.merchant;
        let basicAuth = authHeader();
        return axios.get(url, { headers: basicAuth });
    }

    markProductsAsSoldOut(payload){
        let user = JSON.parse(localStorage.getItem('user'));
        payload.merchant = user.merchant; //Need this for security check for cross merchant hacking

        let url = '/api/product/markProductsAsSoldOut';
        let basicAuth = authHeader();
        return axios.post(url,
            payload,
            {headers: basicAuth}
        );
    }

    unmarkProductsAsSoldOut(merchant, soldOutItemDocId){
        let url = '/api/product/unmarkProductsAsSoldOut';
        let basicAuth = authHeader();
        return axios.post(url,
            {
                merchant: merchant,
                soldOutItemDocId: soldOutItemDocId
            },
            {headers: basicAuth}
        );
    }
}

export default new ProductService();
