<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <nav id="nav" v-if="currentUser" class="navbar navbar-expand-md bg-faded navbar-dark bg-dark justify-content-center">
      <div class="container">
        <a v-if="useFunctionality !== 'WEBSITE'" href="/" class="navbar-brand d-flex me-auto"><img src="@/assets/images/logo.png" width="60" alt="Cagista" /></a>
        <a v-else href="#" class="navbar-brand d-flex me-auto"><img src="@/assets/images/logo.png" width="60" alt="Cagista" /></a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapsingNavbar3">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
          <ul class="navbar-nav bg-faded navbar-dark bg-dark w-100 justify-content-end">
            <li v-if="useFunctionality !== 'WEBSITE'" class="nav-item">
              <a class="nav-link" href="/overview">Oversikt</a>
            </li>
            <li v-if="useFunctionality !== 'WEBSITE'" class="nav-item">
              <a class="nav-link" href="/orders">Ordrer</a>
            </li>
            <li v-if="useFunctionality !== 'WEBSITE' && useOnlinePayment" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="reportScrollingDropdown" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false">Rapporter</a>
              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
                <li class="container"><a class="dropdown-item" href="/x-report">X-Rapport</a></li>
                <li class="container"><a class="dropdown-item" href="/z-report">Z-Rapport</a></li>
                <li v-if="isSuperAdmin" class="container"><a class="dropdown-item" href="/webshop-xreport">Ta dagens oppgjør for nettbutikken</a></li>
                <li v-if="isSuperAdmin" class="container"><a class="dropdown-item" href="/webshop-zreport">Oppgjørsrapport for nettbutikken</a></li>
                <li class="container"><a class="dropdown-item" href="/totalSalesReport">Omsetningsrapport</a></li>
                <li v-if="payForSMS || isSuperAdmin" class="container"><a class="dropdown-item" href="/smsReport">SMS rapport</a></li>
                <li v-if="isSuperAdmin" class="container"><a class="dropdown-item" href="/xReportRegenerate">(Superadmin) Regenerere oppgjørsrapport for en bestemt dag</a></li>
                <li v-if="isSuperAdmin" class="container"><a class="dropdown-item" href="/webshopXReportRegenerateAll">(Superadmin) NOT WORKS AFTER QR - Regenerere ALLE webshop oppgjørsrapport for en merchant </a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/products">Produkter</a>
            </li>
            <li v-if="showUsersPage" class="nav-item">
              <a class="nav-link" href="/users">Ansatte</a>
            </li>
            <li v-if="useFunctionality === 'POS' || useFunctionality === 'ALL'" class="nav-item">
              <a class="nav-link" href="/terminals">Terminaler</a>
            </li>
            <li v-if="useFunctionality !== 'WEBSITE' && (offerDiscount || offerRewardProgram)" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="supportScrollingDropdown" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false">Lojalitet</a>
              <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                <li v-if="offerDiscount" class="container"><a class="dropdown-item" href="/discounts">Rabatter</a></li>
                <li v-if="offerRewardProgram" class="container"><a class="dropdown-item" href="/rewards">Bonusprogram</a></li>
              </ul>
            </li>
            <li :class="isSuperAdmin ? 'nav-item dropdown' : 'nav-item dropdown me-5'">
              <a class="nav-link dropdown-toggle" href="#" id="settingsScrollingDropdown" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false">Innstillinger</a>
              <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                <li class="container"><a class="dropdown-item" href="/closingdays">Stengte dager</a></li>
                <li class="container"><a class="dropdown-item" href="/openinghours">Åpningstider</a></li>
                <li v-if="isSuperAdmin || useFunctionality === 'ALL'" class="container"><a class="dropdown-item" href="/tables">Bord</a></li>
                <li v-if="offerDeliveryService && useFunctionality !== 'WEBSITE'" class="container"><a class="dropdown-item" href="/delivery">Levering</a></li>
                <li v-if="useFunctionality !== 'WEBSITE'" class="container"><a class="dropdown-item" href="/vat">Merverdiavgift (MVA)</a></li>
                <li class="container"><a class="dropdown-item" href="/company">Firma</a></li>
                <li v-if="isSuperAdmin || hasMultiLocations" class="container"><a class="dropdown-item" href="/locations">Avdelinger</a></li>
                <li v-if="isSuperAdmin" class="container"><a class="dropdown-item" href="/dintero">Dintero</a></li>
                <li v-if="isSuperAdmin" class="container"><a class="dropdown-item" href="/merchant">Merchant</a></li>
              </ul>
            </li>
            <li v-if="isSuperAdmin" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="supportScrollingDropdown" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false">Support</a>
              <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                <li class="container"><a class="dropdown-item" href="/cloudprnt">CloudPRNT</a></li>
              </ul>
            </li>
            <li v-if="isSuperAdmin" class="nav-item dropdown me-5">
              <a class="nav-link dropdown-toggle" href="#" id="supportScrollingDropdown" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false">System</a>
              <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                <li class="container"><a class="dropdown-item" href="/cache">Cache</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="profile" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false"><font-awesome-icon icon="user" /></a>
              <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                <li class="container"><a class="dropdown-item" href="/profile">Profil</a></li>
                <li class="container"><a class="dropdown-item" @click.prevent="logOut">Logg ut</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div>
      <router-view />
    </div>

    <ScrollToTop></ScrollToTop>

    <footer id="footer" class="mt-auto bg-dark">
      <div class="container clearfix">
        <span class="float-start" style="color:#909497">© {{currentYear}} {{merchant}}</span>
        <div class="float-end">
          <a class="footer-link" href="http://www.cagista.no">Cagista®</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ScrollToTop from "@/components/other/ScrollToTop";
import UserUtil from "@/util/UserUtil";
import LocationUtil from "@/util/LocationUtil";
import MerchantUtil from "@/util/MerchantUtil";

export default {
  components: {ScrollToTop},
  computed: {
    useFunctionality(){
      return MerchantUtil.useFunctionality();
    },
    useOnlinePayment(){
      let locations = LocationUtil.getLocations();
      for(let i = 0 ; i < locations.length; i++){
        let location = locations[i];
        if(location.onlinePaymentProvider != 'NOT_ANY'){
          return true;
        }
      }
      return false;
    },
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },
    isSuperAdmin(){
      return UserUtil.isSuperAdmin();
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    merchant() {
      if(this.currentUser){
        return this.$store.state.auth.user.merchant.toUpperCase();
      } else {
        return '';
      }
    },
    currentYear(){
      return (new Date()).getFullYear();
    },
    offerDeliveryService(){
      return MerchantUtil.offerDeliveryService();
    },
    userProfilePageName(){
      return MerchantUtil.userProfilePageName();
    },
    showUsersPage(){
      return MerchantUtil.showUsersPage();
    },
    payForSMS(){
      return MerchantUtil.payForSMS();
    },
    offerDiscount(){
      return MerchantUtil.offerDiscount();
    },
    offerRewardProgram(){
      return MerchantUtil.offerRewardProgram();
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap';

footer {
  background-color:#212529;
  color:#fff;
  height:50px;
  padding-top:10px;
  padding-left:10px;
  padding-right:10px;

  position: relative;
  clear:both;
}

footer .nav>li>a {
  padding:3px;
  color:#ccc;
}

footer .nav>li>a:hover {
  background-color:transparent;
  color:#fff;
}

.footer-link{
  color:#909497;
  text-decoration: none;
}

.footer-link:hover{
  text-decoration: none;
}
</style>
