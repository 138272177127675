<template>
  <div class="container">
    <LoadingSpinner :loading="loading"> </LoadingSpinner>

    <div class="mt-2">
      <div class="row d-flex">
        <div class="col-sm-6">
          <div>
            <div class="controlPanel">
              <ControlPanel v-if="!loading" :locations="locations" :closingDaysArray="closingDaysArray" :categories="categories" :soldOutItems="soldOutItems"/>
            </div>
          </div>
          <div>
            <div class="totalSalesTodayPanel">
              <div class="totalSalesTodayContainer">
                <h2>Dagens totale salg</h2>
                <span class="totalSalesToday"> {{ totalSalesToday }} kr</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="d-flex justify-content-center totalSalesPerTypeToday">
            <TotalSalesPerTypeToday v-if="!loading" :totalSalesTodayByTypeLabels="totalSalesTodayByTypeLabels" :totalSalesTodayByType="totalSalesTodayByType" />
          </div>
        </div>
      </div>

      <div class="tab">
        <button id="dailySalesBtn" class="tablinks active" @click="showAnalytics($event, 'dailySales')">Siste 30 dagene</button>
        <button id="monthlySalesBtn" class="tablinks" @click="showAnalytics($event, 'monthlySales')">Månedlig</button>
      </div>
      <div id="dailySales" class="tabcontent" style="display:block">
        <TotalSalesPerDate v-if="!loading" :totalSalesPerDateLabels="totalSalesPerDateLabels" :totalSalesPerDate="totalSalesPerDate" />
      </div>
      <div id="monthlySales" class="tabcontent" :class="currentTab === index ? 'active' : ''">
        <TotalSalesPerMonth v-if="!loading" :totalSalesPerMonthLabels="totalSalesPerMonthLabels" :totalSalesPerMonth="totalSalesPerMonth" />
      </div>
    </div>
  </div>
</template>

<script>
import ControlPanel from "@/components/other/ControlPanel";
import LoadingSpinner from "@/components/other/LoadingSpinner";
import TotalSalesPerTypeToday from "@/components/chart/TotalSalesPerTypeToday";
import TotalSalesPerDate from "@/components/chart/TotalSalesPerDate";
import TotalSalesPerMonth from "@/components/chart/TotalSalesPerMonth";
import DashboardService from "@/services/dashboard/DashboardService";
import LocationUtil from "@/util/LocationUtil";

export default {
  components: {ControlPanel, TotalSalesPerTypeToday, TotalSalesPerDate, TotalSalesPerMonth, LoadingSpinner},
  data() {
    return {
      loading: true,
      currentTab: 0,

      totalSalesToday: 0,
      totalSalesTodayByTypeLabels: [],
      totalSalesTodayByType: [],
      totalSalesPerDateLabels: [],
      totalSalesPerDate: [],
      totalSalesPerMonthLabels: [],
      totalSalesPerMonth: [],
      closingDaysArray: [],
      locations: [],
      categories: [],
      soldOutItems: []
    };
  },
  created() {
    DashboardService.getDashboardData().then(
        (response) => {
          let dashboardData = response.data;
          //console.log('dashboardData: ' + JSON.stringify(dashboardData));

          this.totalSalesToday = dashboardData.totalSalesToday;
          this.totalSalesTodayByTypeLabels = dashboardData.totalSalesTodayByTypeLabels;
          this.totalSalesTodayByType = dashboardData.totalSalesTodayByType;

          this.totalSalesPerDateLabels = dashboardData.totalSalesPerDateLabels;
          this.totalSalesPerDate = dashboardData.totalSalesPerDate;

          this.totalSalesPerMonthLabels = dashboardData.totalSalesPerMonthLabels;
          this.totalSalesPerMonth = dashboardData.totalSalesPerMonth;

          this.closingDaysArray = dashboardData.closingDaysArray;

          this.locations = dashboardData.locations;

          this.categories = dashboardData.categories;
          //console.log('categories: ' + JSON.stringify(this.categories));

          this.soldOutItems = dashboardData.soldOutItems;

          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
    );
  },
  methods: {
    hasMultiLocations(){
      return LocationUtil.hasMultiLocations();
    },
    showAnalytics: function(evt, analyticsType) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(analyticsType).style.display = "block";
      evt.target.className += " active";
    }
  }
};
</script>

<style scoped>
.controlPanel {
  background-color: #FFFFFF;
  margin-bottom: 20px;
}

.totalSalesPerTypeToday {
  background-color: #E8E8E8;
}

.totalSalesTodayPanel {
  background-color: #FFFFFF;
}

.totalSalesTodayContainer{
  background-color: #E8E8E8;
  padding: 20px;
}

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #fafafa;
  margin-lef: 3px;
  margin-top: 20px;
}

.tablinks {
  transform: translate(-12px, 0px);
}

.tab button {
  background-color: #e5e5ef;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}
.tab button:hover {
  background-color: #ddd;
}
.tab button.active {
  background-color: #ccc;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
  margin-bottom: 32px;
}

.totalSalesToday{
  font-size: 32px;
  font-weight: bold;
  color: #B30E10;
}
</style>
